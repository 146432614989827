import React, { useState, useEffect, useRef } from "react";
import "./NewsTiker.css"
const NewsTicker = ({ news }) => {
    const [tickerPosition, setTickerPosition] = useState(-100);
    const [isPaused, setIsPaused] = useState(false);
    const isPausedRef = useRef(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const handleMouseEnter = () => {
            setIsPaused(true);
            isPausedRef.current = true;
        };

        const handleMouseLeave = () => {
            setIsPaused(false);
            isPausedRef.current = false;
        };

        const handleClick = () => {
            setIsPaused((isPaused) => !isPaused);
            isPausedRef.current = !isPaused;
        };

        container.addEventListener("mouseenter", handleMouseEnter);
        container.addEventListener("mouseleave", handleMouseLeave);
        container.addEventListener("click", handleClick);

        const intervalId = setInterval(() => {
            if (!isPausedRef.current) {
                setTickerPosition((tickerPosition) => {
                    const nextPosition = tickerPosition - 100;
                    return nextPosition < -100 * news.length ? 0 : nextPosition;
                });
            }
        }, 2000);

        return () => {
            clearInterval(intervalId);
            container.removeEventListener("mouseenter", handleMouseEnter);
            container.removeEventListener("mouseleave", handleMouseLeave);
            container.removeEventListener("click", handleClick);
        };
    }, [news]);

    return (
        <div className="news-ticker-container" ref={containerRef}>
            <div
                className="news-ticker"
                style={{ transform: `translateX(${tickerPosition}%)` }}
            >
                {news.map((item, index) => (
                    <span key={index} className="news-item">
            {item}
          </span>
                ))}
            </div>
        </div>
    );
};

export default NewsTicker;
