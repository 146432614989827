import classes from './MealsSummary.module.css';

const MealsSummary = ({title,firstDescription,secondDescription}) => {
  return (
    <section className={classes.summary}>
      <h2>{title}</h2>
      <p>
          {firstDescription}
      </p>
      <p>
          {secondDescription}
      </p>
    </section>
  );
};

export default MealsSummary;
