import {Fragment, useEffect, useState} from 'react';
import MealsSummary from "../Meals/MealsSummary";
import classes from "../Meals/AvailableMeals.module.css";
import Card from "../UI/Card";
import RecipeCard from "./Recipe/RecipeCard";
import apiService from "../../mongo/apiService";
import {Modal} from "antd"

const Ricettario = ({setCurrentLoadingState}) => {
    const [recipes, setRecipes] = useState()

    useEffect(() => {
        setCurrentLoadingState(true)
        apiService.getAllRicette().then((response) => {
            setRecipes(response.data)
            setCurrentLoadingState(false)
        }).catch((error) => {
            setCurrentLoadingState(false)
            Modal.error({
                title: 'Errore',
                content: 'Errore nel caricamento delle ricette'
            })
        })
    }, [])
    return (
        <Fragment>
            <MealsSummary title={"Ricettario dei prodotti dimenticati"}
                          firstDescription={"In questa sezione vi illustriamo la preparazione di piatti unici realizzati con i nostri prodotti"}
                          secondDescription={"tutto quello che cè da sapere sui prodotti dimenticati, buona visione!"}/>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'Center',
                    alignItems: 'Center',
                }}
            >
                <section className={classes.meals}>
                    <Card>

                        {
                            recipes && recipes.map((recipe,index) => (
                                    <RecipeCard key={index} recipe={recipe}/>
                                )
                            )
                        }
                    </Card>
                </section>
            </div>
        </Fragment>
    );
};

export default Ricettario;