import {useReducer} from 'react';

import CartContext from './cart-context';

const defaultCartState = {
    items: [],
    totalAmount: 0,
    recipe : {}
};

const cartReducer = (state, action) => {

    if (action.type === 'ADD') {
        const updatedTotalAmount = parseFloat((state.totalAmount + action.item.price * action.item.amount).toFixed(2));
        const existingCartItemIndex = state.items.findIndex(
            (item) => item.id === action.item.id
        );
        const existingCartItem = state.items[existingCartItemIndex];
        let updatedItems;

        if (existingCartItem) {
            const updatedItem = {
                ...existingCartItem,
                amount: existingCartItem.amount + action.item.amount,
            };
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        } else {
            updatedItems = state.items.concat(action.item);
        }

        return {
            items: updatedItems,
            totalAmount: updatedTotalAmount,
        };
    }
    if (action.type === 'REMOVE') {
        const existingCartItemIndex = state.items.findIndex(
            (item) => item.id === action.id
        );
        const existingItem = state.items[existingCartItemIndex];
        const updatedTotalAmount = parseFloat((state.totalAmount - existingItem.price).toFixed(2));
        if (existingItem.amount <= 1) {
            const updatedItems = state.items.filter(item => item.id !== action.id);
            return {
                items: updatedItems,
                totalAmount: updatedTotalAmount > 0 ? updatedTotalAmount : 0
            };
        } else {
            const updatedItem = {...existingItem, amount: existingItem.amount - 1};
            const updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
            return {
                items: updatedItems,
                totalAmount: updatedTotalAmount > 0 ? updatedTotalAmount : 0
            };
        }
    }
    if (action.type === 'REMOVE_ALL') {
        return defaultCartState;
    }
    if (action.type === 'SET_RECIPE') {
        return { ...state, recipe: action.recipe };
    }

    return defaultCartState;
};
const CartProvider = (props) => {
    const [cartState, dispatchCartAction] = useReducer(
        cartReducer,
        defaultCartState
    );

    const addItemToCartHandler = (item) => {
        dispatchCartAction({type: 'ADD', item: item});
    };

    const removeItemFromCartHandler = (id) => {
        dispatchCartAction({type: 'REMOVE', id: id});
    };
    const removeAllItemsFromCartHandler = () => {
        dispatchCartAction({type: 'REMOVE_ALL'});
    };

    const setRecipeHandler = (recipe) => {
        dispatchCartAction({ type: 'SET_RECIPE', recipe: recipe });
    };
    const cartContext = {
        items: cartState.items,
        totalAmount: cartState.totalAmount,
        recipe: cartState.recipe,
        addItem: addItemToCartHandler,
        removeItem: removeItemFromCartHandler,
        removeAllItems: removeAllItemsFromCartHandler,
        setRecipe: setRecipeHandler

    };


    return (
        <CartContext.Provider value={cartContext}>
            {props.children}
        </CartContext.Provider>
    );
};

export default CartProvider;
