import Card from '../UI/Card';
import MealItem from './MealItem/MealItem';
import classes from './AvailableMeals.module.css';
import React, {useEffect, useState} from 'react';
import apiService from '../../mongo/apiService';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AvailableMeals = ({setCurrentLoadingState}) => {
    const [mealsList, setMealsList] = useState([]);
    const [filter, setFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');

    useEffect(() => {
        setCurrentLoadingState(true);
        apiService
            .getAllProduct()
            .then((response) => {
                setMealsList(response.data);
                setCurrentLoadingState(false);
            })
            .catch((error) => {
                setMealsList([]);
                setCurrentLoadingState(false);
            });
    }, []);

    const handleFilterChange = (selectedOption, action) => {
        if (action.action === 'input-change') {
            setFilter(selectedOption);
        } else if (action.action === 'clear') {
            setFilter('');
        } else if (action.action === 'select-option' && selectedOption) {
            setFilter(selectedOption.value);
        } else {
            setFilter(selectedOption ? selectedOption.value : '');
        }
    };

    const handleCategoryFilterChange = (selectedOption, action) => {
        if (action.action === 'input-change') {
            setCategoryFilter(selectedOption);
        } else if (action.action === 'clear') {
            setCategoryFilter('');
        } else if (action.action === 'select-option' && selectedOption) {
            setCategoryFilter(selectedOption.value)
            console.log("cambio categoria: %o", selectedOption.value);
        }else {
            setCategoryFilter(selectedOption ? selectedOption.value : '');
        }
    };


    const uniqueCategories = [
        ...new Set(mealsList.map((meal) => meal.category).filter((category) => category)),
    ];

    const categoryOptions = uniqueCategories.map((category) => {
        return {value: category, label: category};
    });

    const filteredMealsList = mealsList.filter((meal) => {
        const nameFilterMatch = filter
            ? meal.name.toLowerCase().includes(filter.toLowerCase())
            : true;
        const categoryFilterMatch = categoryFilter
            ? meal.category && meal.category.toLowerCase() === categoryFilter.toLowerCase()
            : true;
        return nameFilterMatch && categoryFilterMatch;
    });
    const mealOptions = filteredMealsList.map((meal) => {
        return {value: meal.name, label: meal.name};
    });
// useEffect(() => {
//
// }, [filter, categoryFilter])

    const meaListComp = filteredMealsList.map((meal) => (
        <MealItem
            key={meal.id}
            id={meal.id}
            name={meal.name}
            description={meal.description}
            price={meal.price}
            saleType={meal.saleType}
        />
    ));
    console.log("filteredMealsList %o", filteredMealsList);
    console.log("mealOptions %o", mealOptions);

    return (
        <section className={classes.meals}>
            <Row>
                <Col>
                    <section className={classes.meals}>
                        <Card>
                            <Form.Label>Nome prodotto</Form.Label>
                            <Select
                                value={filter ? {value: filter, label: filter} : null}
                                options={mealOptions}
                                onChange={handleFilterChange}
                                isClearable
                                placeholder="Cerca per nome"
                            />
                        </Card>
                    </section>
                </Col>
                <Col>
                    <section className={classes.meals}>
                        <Card>
                            <Form.Label>Categoria Prodotto</Form.Label>
                            <Select
                                value={categoryFilter ? {value: categoryFilter, label: categoryFilter} : null}
                                options={categoryOptions}
                                onChange={handleCategoryFilterChange}
                                isClearable
                                placeholder="Cerca per categoria"
                            />
                        </Card>
                    </section>
                </Col>
            </Row>
            <Card>
                <ul>{meaListComp}</ul>
            </Card>
        </section>
    );
};

export default AvailableMeals