import {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Header from './components/Layout/Header';
import Meals from './components/Meals/Meals';
import Cart from './components/Cart/Cart';
import CartProvider from './store/CartProvider';
import Ricettario from "./components/Ricettario/Ricettario";
import {Route, Routes} from 'react-router-dom';
import classes from "./components/Layout/Header.module.css";
import mealsImage from "./assets/backgroudimgdecorator/meals.png";
import ModalConfirm from "./components/UI/ModalConfirm";
import {FooterContainer} from "./components/    FooterContainer/FooterContainer";
import StatoOrdine from "./components/statoOrdine/statoOrdine";
import PrivacyComponent from "./components/Privacy/PrivacyComponent";
import ScrolTop from "./components/ScrolTop/ScrolTop";
import {useCookies} from "react-cookie";
import RecipeDetail from "./components/Ricettario/Recipe/RecipeDetail";
import SpinnerHearth from "./assets/spinner/SpinnerHearth";

function App() {
    const [cartIsShown, setCartIsShown] = useState(false);
    const [cartResposeShow, setCartResposeShow] = useState(false);
    const [currentLoadingState, setCurrentLoadingState] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    const [modalIsOpen, setModalIsOpen] = useState(()=>{
        return !cookies['acceptedPrivacyPolicy'];
    });
    const showCartHandler = () => {
        setCartIsShown(true);
    };

    const hideCartHandler = () => {
        setCartIsShown(false);
    };

    useEffect(() => {
        if (!cookies['acceptedPrivacyPolicy']) {
            setModalIsOpen(true);
        }
    }, []);

    return (
        <CartProvider>
            {cartIsShown && <Cart onClose={hideCartHandler} setCartResposeShow={setCartResposeShow}/>}
            {cartResposeShow && <ModalConfirm setCartResposeShow={setCartResposeShow}  />}
            <Header onShowCart={showCartHandler}/>
            <div className={classes['main-image']}>
                <img src={mealsImage} alt='A table full of delicious food!'/>
            </div>
            <main className={classes['backroud-main']}>
                <ScrolTop>
                    <Routes>
                        <Route path='/' exact element={<Meals setCurrentLoadingState={setCurrentLoadingState}/>}/>
                        <Route path='/ricettario' element={<Ricettario setCurrentLoadingState={setCurrentLoadingState}/>}/>
                        <Route path='/statoOrdine' element={<StatoOrdine setCurrentLoadingState={setCurrentLoadingState}/>}/>
                        <Route path="/ricettario/:prodotto/:ricetta" element={<RecipeDetail setCurrentLoadingState={setCurrentLoadingState}/>}/>
                    </Routes>
                </ScrolTop>
            </main>
            {modalIsOpen? <PrivacyComponent cookies={cookies} setCookie={setCookie} setModalIsOpen={setModalIsOpen} /> : null}
            <FooterContainer />
            <SpinnerHearth isLoading={currentLoadingState}/>
        </CartProvider>
    );
}

export default App;
