import {Fragment} from 'react';

import HeaderCartButton from './HeaderCartButton';
import classes from './Header.module.css';
import NavbarStyled from "../Navbar/NavbarComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TiketNews from "../TiketNews/TiketNews";
const news = [
    "Le mele biologiche di produzione locale sono finalmente disponibili!",
    "Assaggia le nostre carote biologiche appena raccolte, dolci e croccanti come non mai",
];
const Header = (props) => {
    return (
        <Fragment>
            <header className={classes.header}>
                <Row>
                    <Col className={classes.inlienDisplay}>
                        <h1>Barbu Florentina</h1>
                        <HeaderCartButton onClick={props.onShowCart}/>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <NavbarStyled/>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <TiketNews news={news}/>
                    </Col>
                </Row>
            </header>
        </Fragment>
    );
};

export default Header;
