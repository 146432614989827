import axios from "axios";
import config from "./config.json";

class ApiService {
    constructor(mock = false) {
        this.instance = axios.create({
            baseURL: config.BASEURL
        });
        this.mock = mock;
        this.nomeBanco = "barbuFlorentina";
    }


    async getAllProduct() {
        try {
            let response;
            if (this.mock) {
                // await new Promise(resolve => setTimeout(resolve, 20000));

                response = await axios.get('/mock/contratti.json');
            } else {
                response = await this.instance.get("/clienti/getProdotti");
            }
            return response;
        } catch (error) {
            throw error;
        }
    }
    async insertOrder(order) {
        order.nomeBanco="barbuFlorentina";
        try {
            let response;
            if (this.mock) {
                // await new Promise(resolve => setTimeout(resolve, 20000));

                response = await axios.get('/mock/contratti.json');
            } else {
                response = await this.instance.post("/clienti/insertOrdine",order);
            }
            return response;
        } catch (error) {
            throw error;
        }
    }
    async searchOrders(telNumber) {
        try {
            let response;
            if (this.mock) {
                // await new Promise(resolve => setTimeout(resolve, 20000));

                response = await axios.get('/mock/contratti.json');
            } else {
                response = await this.instance.post("/clienti/getOrdiniByTel",telNumber);
            }
            return response;
        } catch (error) {
            throw error;
        }
    }
    async getAllRicette() {
        try {
            let response;
            if (this.mock) {
                // await new Promise(resolve => setTimeout(resolve, 20000));

                response = await axios.get('/mock/contratti.json');
            } else {
                response = await this.instance.get("/clienti/getAllRicette?nomeBanco="+this.nomeBanco);
            }
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default new ApiService();