import Footer from "./Footer/Footer";
import {Icon} from "./Icons/style/iconStyle";

export function FooterContainer() {
    return (
        <Footer>
            <Footer.Wrapper>
                <Footer.Row>
                    <Footer.Column>
                        <Footer.Title>Servizi</Footer.Title>
                        <Footer.Link href="#">Ricettario</Footer.Link>
                        <Footer.Link href="#">Verifica stato ordine</Footer.Link>
                    </Footer.Column>
                    <Footer.Column>
                        <Footer.Title>Contatti</Footer.Title>
                        <Footer.Link href="#">Italia</Footer.Link>
                    </Footer.Column>
                    <Footer.Column>
                        <Footer.Title>Social</Footer.Title>
                        <Footer.Link href="https://it-it.facebook.com/barbu.florentina.14" target="_blank" rel="noopener noreferrer"><Icon className="fab fa-facebook-f" />Facebook</Footer.Link>
                    </Footer.Column>
                </Footer.Row>
            </Footer.Wrapper>
        </Footer>
    )
}