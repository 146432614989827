import {useContext, useEffect, useRef, useState} from 'react';

import classes from './MealItem.module.css';
import CartContext from '../../../store/cart-context';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Input from "../../UI/Input";


const MealItem = (props) => {
    const cartCtx = useContext(CartContext);
    const [amountValue, setAmountValue] = useState('1');
    const price = `€${props.price.toFixed(2)}`;
    const [amountIsValid, setAmountIsValid] = useState(true);
    const [image, setImage] = useState('');
    const amountInputRef = useRef();




    const addToCartHandler = amount => {
        cartCtx.addItem({
            id: props.id,
            name: props.name,
            amount: amount,
            price: props.price,
            saleType: props.saleType
        });
    };



    const submitHandler = (event) => {
        event.preventDefault();

        const enteredAmount = amountInputRef.current.value;
        const enteredAmountNumber = parseFloat(enteredAmount.replace(',', '.'));

        if (isNaN(enteredAmountNumber) || enteredAmountNumber <= 0) {
            setAmountIsValid(false);
            return;
        }

        addToCartHandler(enteredAmountNumber);
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        const newValueNumber = parseFloat(newValue.replace(',', '.'));

        if (isNaN(newValueNumber) || newValueNumber <= 0) {
            setAmountIsValid(false);
            setAmountValue(newValue);
        } else {
            setAmountIsValid(true);
            setAmountValue(newValue);
        }
    };
    useEffect(() => {
        const fetchImage = async () => {
            let img;
            try {
                img = await import(`../../../assets/mealImg/${props.name.toLowerCase()}.jpg`);
            } catch (error) {
                try {
                    img = await import(`../../../assets/mealImg/${props.name.toLowerCase()}.png`);
                } catch (error) {
                    img = await import('../../../assets/mealImg/default.png');
                }
            }
            setImage(img.default);
        };
        fetchImage();
    }, [props.name]);

    useEffect(() => {
        const enteredValue = amountInputRef.current.value;
        const enteredValueNumber = parseFloat(enteredValue.replace(',', '.'));

        if (!isNaN(enteredValueNumber) && enteredValueNumber > 0) {
            setAmountValue(enteredValue);
        }
    }, [amountInputRef]);
    return (
        <li className={classes.meal}>
            <Row className={"align-items-center pb-2"}>
                <Col className={""}>
                    <h3>{props.name}</h3>
                    <div className={classes.description}>{props.description}</div>
                    <div className={classes.price}>{price}</div>
                </Col>
                <Col className={"d-flex justify-content-center"}>
                    <div className={classes.imageContainer}>
                        <img src={image} alt={props.name} className={classes.imgCircular}/>
                    </div>
                </Col>
                <Col className={"d-flex justify-content-center"}>
                    <div>
                        <Input
                            ref={amountInputRef}
                            label='Quantità'
                            input={{
                                id: 'amount_' + props.id,
                                type: 'text',
                                placeholder: 'Inserisci la quantità (es. 1,250 kg)',
                                value: amountValue,
                                onChange: handleInputChange,
                            }}
                        /></div>
                </Col>
            </Row>
            <Row>
                <button onClick={submitHandler} className={classes.button}>
                    + Aggiungi{" "}
                    {amountValue &&
                        `${parseFloat(amountValue.replace(",", ".")) >= 1
                            ? parseFloat(amountValue.replace(",", "."))
                            : parseFloat(amountValue.replace(",", ".")) * 1000} ${
                            !props.saleType || props.saleType === "peso"
                                ? parseFloat(amountValue.replace(",", ".")) >= 1
                                    ? "kg"
                                    : "g"
                                : props.saleType === "pezzo"
                                    ? "pz"
                                    : props.saleType === "mazzo"
                                        ? "mz"
                                        : ""
                        }`}
                </button>
            </Row>


        </li>
    );
};

export default MealItem;
