import {useContext, useState} from 'react';

import Modal from '../UI/Modal';
import CartItem from './CartItem';
import classes from './Cart.module.css';
import CartContext from '../../store/cart-context';
import FormOrder from "../Forms/FormOrder";

const Cart = (props) => {
    const [castomerDetail, setCastomerDetail] = useState(false);
    const cartCtx = useContext(CartContext);

    const totalAmount = `€${cartCtx.totalAmount.toFixed(2)}`;
    const hasItems = cartCtx.items.length > 0;

    const cartItemRemoveHandler = (id) => {
        cartCtx.removeItem(id);
    };

    const cartItemAddHandler = (item) => {
        cartCtx.addItem({...item, amount: 1});
    };
    const removeAllHandler = () => {
        cartCtx.removeAllItems();
    }
    const cartItems = (
        <ul className={classes['cart-items']}>
            {cartCtx.items.map((item) => (
                <CartItem
                    key={item.id}
                    name={item.name}
                    amount={item.amount}
                    price={item.price}
                    onRemove={cartItemRemoveHandler.bind(null, item.id)}
                    onAdd={cartItemAddHandler.bind(null, item)}
                />
            ))}
        </ul>
    );

    function onPreOrder() {
        setCastomerDetail(true);
    }

    const getModal = () => {
        return <Modal onClose={props.onClose}>
            {cartItems}
            <div className={classes.total}>
                <span>Totale spesa</span>
                <span>{totalAmount}</span>
            </div>
            <div className={classes.actions}>
                <button className={classes['button--alt']} onClick={props.onClose}>
                    Chiudi
                </button>
                {hasItems && <button className={classes.button} onClick={onPreOrder}>Ordina</button>}
            </div>
        </Modal>;
    }

    function onOrder() {
        console.log("invio dell' ordine in corso")
        props.onClose();
    }

    const getModalCustomers = () => {
        return <Modal onClose={props.onClose}>
            <h1>Inserire Dati</h1>
            <div className={classes.actions}>
                <FormOrder hasItems={hasItems} onClose={props.onClose} cartCtx={cartCtx} setCartResposeShow={props.setCartResposeShow} removeAll={removeAllHandler}/>
            </div>
        </Modal>;
    }



    return (
        <>
            {castomerDetail ? getModalCustomers() : getModal()}
        </>
    );
};

export default Cart;
