import React from 'react';

const CartContext = React.createContext({
    items: [],
    totalAmount: 0,
    recipe: {},
    addItem: (item) => {
    },
    removeItem: (id) => {
    },
    removeAllItems: () => {
    },
    setRecipe: (recipe) => {
    },

});

export default CartContext;