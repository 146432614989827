import ReactDOM from "react-dom";
import "./SpinnerHearth.css"
const SpinnerHearth = ({isLoading}) => {
    const spinnerElement = (
        <div className={`spinner-overlay ${isLoading ? '' : 'd-none'}`}>
            <div className="blur-background"></div>
            <div className="lds-heart">
                <div></div>
            </div>
        </div>
    );
    const spinnerRoot = document.getElementById('spinner');
    return ReactDOM.createPortal(spinnerElement, spinnerRoot);
}

export default SpinnerHearth;