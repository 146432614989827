import React, {useContext, useEffect} from 'react';
import {FaClock, FaDiagnoses, FaFireAlt} from "react-icons/fa";
import './Recipe.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Image} from "antd";
import {Link, useLinkClickHandler, useNavigate} from "react-router-dom";
import CartContext from "../../../store/cart-context";

const RecipeCard = ({recipe}) => {

    const cartCtx = useContext(CartContext);
    const navigate = useNavigate();
    console.log("constext from recipecard : %o", cartCtx);

    const linkClickHandler = (event) => {
        event.preventDefault();

        cartCtx.setRecipe(recipe);

        // Use the 'navigate' function to programmatically navigate after setting the recipe.
        navigate(`/ricettario/${recipe.titolo.trim().replace(/\s+/g, '').toLowerCase()}/${recipe.titolo.trim().replace(/\s+/g, '').toLowerCase()}`);
    }

    return (
        <div className="card mt-2">
            <Row className="align-items-start">
                <Col xs={'12'} md={'auto'}>
                    <Image src={recipe.immagine} alt={recipe.titolo} style={{maxWidth: '100%', height: 'auto'}}/>
                </Col>
                <Col xs={12} md={6} className="d-flex flex-column justify-content-between">
                    <Row>
                        <h2 style={{color: 'green'}}>{recipe.titolo}</h2>
                    </Row>
                    <Row>
                        <p>{recipe.descrizioneShort}...<Link to="#" onClick={linkClickHandler}>Leggi tutto</Link></p>

                    </Row>
                    <Row>
                        <Col>
                            <p><FaFireAlt/> {recipe.kcal} kcal</p>
                        </Col>
                        <Col xs={'auto'}>
                            <p><FaClock/> {recipe.tempoPreparazione}</p>
                        </Col>
                        <Col xs={'auto'}>
                            <p><FaDiagnoses/> {recipe.difficolta} </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>);

}

export default RecipeCard;