import MealsSummary from "../../Meals/MealsSummary";
import {Fragment, useContext} from "react";
import Card from "../../UI/Card"
import classes from "../../Meals/AvailableMeals.module.css"
import {useParams} from "react-router-dom";
import floreal from "../../../assets/spinner/floreal.svg"
import CartContext from "../../../store/cart-context";
import {Image} from "antd";
const RicettaDetail = (props) => {
    // const {prodotto, ricetta} = useParams();

    const cartCtx = useContext(CartContext);
    const ricetta = cartCtx['recipe']
    console.log("constext from ricetttaDetail : %O",cartCtx);
    const renderImages = (images) => {
        if (images.length === 1) {
            return (
                <div className="text-center">
                    <Image src={images[0]} className="img-fluid" alt="Step" />
                </div>
            );
        } else if (images.length > 1) {
            return (
                <div className="row">
                    {images.map((image, index) => (
                        <div className="col" key={index}>
                            <Image src={image} className="img-fluid" alt="Step" />
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    };
    return (
        <Fragment>
            <MealsSummary title="PRESENTAZIONE"
                          firstDescription={ricetta && ricetta['descrizioneLong']||"niente da visualizzare"}
                style={{
                    display: 'flex',
                    justifyContent: 'Center',
                    alignItems: 'Center',
                }}
            ></MealsSummary>
                <section className={classes.meals}>
                    <Card>
                        <h2>Ingredienti</h2>
                        <ul className="list-group">
                            {ricetta['ingredienti'].map((ingredient, index) => (
                                <li className="list-group-item" key={index}>
                                    <span><b>{ingredient.nome}</b></span> - <span>{ingredient.quantita}</span>
                                </li>
                            ))}
                        </ul>
                    </Card>
                </section>
            <section className={classes.meals}>
                <Card>
                    <h2>Preparazione</h2>
                    <ul>
                        { ricetta['procedimento'].map((step, index) => (
                            <li key={index}>
                                <h4>Passo {index + 1}</h4>
                                {renderImages(step.immagini)}
                                <br></br>
                                <p>{step.descrizione}</p>
                            </li>
                        ))}
                    </ul>
                </Card>
            </section>
            {ricetta['conservazione'] && <section className={classes.meals}>
                <Card>
                    <h2>Conservazione</h2>
                    <p>{ricetta['conservazione']}</p>
                </Card>
            </section>}
            {ricetta['consiglio'] && <section className={classes.meals}>
                <Card>
                    <h2>consiglio</h2>
                    <p>{ricetta['consiglio']}</p>
                </Card>
            </section>}
        </Fragment>
    );
}
export default RicettaDetail;
