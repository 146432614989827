
import React, {useEffect, useState} from "react";
import Modal from "../UI/Modal";
import "./Pivacy.css";
import {Button} from "react-bootstrap";
import Row from "react-bootstrap/Row";


function PrivacyComponent({setModalIsOpen,cookies,setCookie}) {

    useEffect(() => {
        if (!cookies['acceptedPrivacyPolicy']) {
            setModalIsOpen(true);
        }
    }, []);

    const handleCloseModal = () => {
        setCookie('acceptedPrivacyPolicy', true, {path: '/',secure: true, sameSite: 'strict'});
        setModalIsOpen(false);
    };

    return (
        <Modal
            onClose={handleCloseModal}

        ><Row style={{float:'right'}}>
            <Button className={'btn btn-danger'} onClick={handleCloseModal}>x</Button>
        </Row>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 px-0">
                            <h5 className="title">Informativa cookie</h5>

                            <p className="text">
                                Il nostro sito utilizza <strong>cookie tecnici</strong> e tecnologie simili indispensabili per il suo corretto funzionamento. Con il tuo consenso, vorremmo anche utilizzare <strong>cookie di profilazione</strong> per offrirti un'esperienza migliore.
                            </p>
                            <p className="text">
                                Se scegli di utilizzare i nostri servizi per effettuare un ordine, acconsenti all'uso del tuo numero di cellulare, ed evenuale indirizzo email e cookie per scopi puramente statistici e per il miglioramento del servizio. Queste informazioni non saranno mai utilizzate per scopi diversi senza il tuo esplicito consenso.
                                Principalemnte i cookie salvati verranno utiliazzati solo da voi per evitatare azzioni di inserimento dati ripetute.
                            </p>
                            <p className="text">
                                Se <strong>chiudi</strong> questo messaggio, tramite la <strong>X</strong> in alto a destra, accetti solo l'uso di cookie strettamente necessari.
                            </p>
                            <p className="text">
                                Ti invitiamo a consultare la nostra <a href="/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a> per maggiori informazioni sul trattamento dei tuoi dati personali.
                            </p>

                            <div className="eu-privacy-buttons-container">
                                <div className="eu-privacy-checks">
                                    <div className="eu-privacy-check d-none">
                                        <input
                                            type="checkbox"
                                            name="eu-privacy-chk-necessary"
                                            id="eu-privacy-chk-necessary"
                                            value="1"
                                            disabled
                                            defaultChecked
                                        />
                                        <label htmlFor="eu-privacy-chk-necessary">
                                            Necessari e tecnici
                                        </label>
                                    </div>

                                    <div className="eu-privacy-check d-none">
                                        <input
                                            type="checkbox"
                                            name="eu-privacy-chk-preferences"
                                            id="eu-privacy-chk-preferences"
                                            value="2"
                                        />
                                        <label htmlFor="eu-privacy-chk-preferences">
                                            Preferenze
                                        </label>
                                    </div>

                                    <div className="eu-privacy-check d-none">
                                        <input
                                            type="checkbox"
                                            name="eu-privacy-chk-statistics"
                                            id="eu-privacy-chk-statistics"
                                            value="4"
                                        />
                                        <label htmlFor="eu-privacy-chk-statistics">
                                            Statistiche
                                        </label>
                                    </div>
                                    <div className="eu-privacy-check d-none">
                                        <input
                                            type="checkbox"
                                            name="eu-privacy-chk-marketing"
                                            id="eu-privacy-chk-marketing"
                                            value="8"
                                        />
                                        <label htmlFor="eu-privacy-chk-marketing">Marketing</label>
                                    </div>
                                </div>

                                <div className="eu-privacy-buttons">
                                    <div className="eu-privacy-button-container pb-2">
                                        <button className={"btn btn-outline-primary"} onClick={handleCloseModal}>
                                            ACCETTA TUTTI
                                        </button>
                                    </div>
                                    <div className="eu-privacy-button-container">
                                        <button className={"btn btn-outline-success"} onClick={handleCloseModal}>
                                            ACCETTA SOLO I NECESSARI
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Modal>
    );
}

export default PrivacyComponent;


