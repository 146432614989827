import React, {useState} from "react";

import classes from "../Cart/Cart.module.css";
import apiService from "../../mongo/apiService";
import "./formOrderStyle.css"
import {useCookies} from "react-cookie";
import FormItem from "antd/es/form/FormItem";
import {DatePicker, TimePicker} from "antd";
import dayjs from "dayjs";
import {Form, Input, Row, Col, Button, Modal} from 'antd';


function FormOrder({hasItems, onClose, cartCtx, setCartResposeShow, removeAll}) {
    const success = (nome, cognome) => {
        Modal.success({
            title: "Grazie mille! " + nome + " " + cognome,
            content: 'Complimenti, il tuo ordine è stato inviato con successo!',
        });
    };
    const [validated, setValidated] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

    const [startDate, setStartDate] = useState(() => {
        let now = dayjs();
        let dayOfWeek = now.day();

        // If today is a weekday (Mon-Fri), find the first Saturday or Sunday
        if (dayOfWeek > 0 && dayOfWeek < 6) {
            if (dayOfWeek === 5) {
                // It's Friday, set start date to tomorrow (Saturday)
                now = now.add(1, 'day');
            } else {
                // It's a weekday, find the first Saturday or Sunday
                while (dayOfWeek !== 0 && dayOfWeek !== 6) {
                    now = now.add(1, 'day');
                    dayOfWeek = now.day();
                }
            }
        }

        return now;
    });
    const handleSubmit = (values) => {
        console.log("chiamata alla api");
        console.log("Prodotti %o", cartCtx);

        // const ore = startDate.getHours().toString().padStart(2, '0');
        // const minuti = startDate.getMinutes().toString().padStart(2, '0');

        let requestObj = {
            nomeCliente: values.user.nome,
            cognomeCliente: values.user.cognome,
            numeroTelefono: values.user.telefono,
            prodottoList: cartCtx["items"],
            prezzoTotale: cartCtx["totalAmount"],
            dataArrivo: new Date(startDate)
        };
        apiService
            .insertOrder(requestObj)
            .then((response) => {
                onClose();
                if (!!response.data) {
                    success(requestObj.nomeCliente, requestObj.cognomeCliente);
                    setCookie('nome', values.user.nome, {path: '/', secure: true, sameSite: 'strict'});
                    setCookie('cognome', values.user.cognome, {path: '/', secure: true, sameSite: 'strict'});
                    setCookie('telefono', values.user.telefono, {path: '/', secure: true, sameSite: 'strict'});
                    setCookie('dataArrivo', requestObj.dataArrivo, {path: '/', secure: true, sameSite: 'strict'});
                    removeAll()
                }
            })
            .catch((error) => {
                onClose();
            });
    };

    function range(size, startAt = 0) {
        return [...Array(size).keys()].map(i => i + startAt);
    }

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const validateMessages = {
        required: '${label} è richiesto!',
        types: {
            number: '${label} non è un numero valido!',
        },
        number: {
            range: '${label} deve essere tra ${min} e ${max}',
        },
    };
    return (
        <Form {...layout} name="nest-messages" onFinish={handleSubmit} validateMessages={validateMessages}
              initialValues={{
                  user: {
                      nome: cookies.nome,
                      cognome: cookies.cognome,
                      telefono: cookies.telefono
                  }
              }}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name={['user', 'nome']} label="Nome" rules={[{required: true}]}>
                        <Input placeholder="Nome"/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={['user', 'cognome']} label="Cognome" rules={[{required: true}]}>
                        <Input placeholder="Cognome"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name={['user', 'telefono']}
                        label="Telefono"
                        rules={[
                            {required: true},
                            () => ({
                                validator(_, value) {
                                    const regex = /^[0-9]*$/;
                                    if (!value || regex.test(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Inserisci solo numeri'));
                                },
                            }),
                        ]}
                    >
                        <Input placeholder="Telefono"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="Data di arrivo">
                        <DatePicker
                            required
                            value={startDate}
                            onChange={setStartDate}
                            disabledDate={current => {
                                // Disabilita le date precedenti a oggi e i giorni della settimana tranne sabato e domenica
                                return current && (current.isBefore(dayjs().endOf('day')) || ![6, 0].includes(current.day()));
                            }}
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Ora di arrivo">
                        <TimePicker
                            required
                            format="HH:mm"
                            minuteStep={15}
                            value={startDate && startDate.clone().minute(0).second(0)} // Resetta i minuti e i secondi
                            onChange={time => {
                                if (startDate) {
                                    const newDate = startDate.clone().hour(time.hour()).minute(time.minute());
                                    setStartDate(newDate);
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <button className={classes["button--alt"]} onClick={onClose}>
                Chiudi
            </button>
            {hasItems && (
                <button className={classes.button} type={"submit"}>
                    Ordina
                </button>
            )}
        </Form>
        // <Form noValidate validated={validated} onSubmit={handleSubmit}>
        //     <Row className="mb-3">
        //         <Form.Group as={Col} md="12" controlId="nome">
        //             <Form.Label>Nome</Form.Label>
        //             <Form.Control
        //                 required
        //                 type="text"
        //                 placeholder="Nome"
        //                 defaultValue={cookies.nome}
        //             />
        //             <Form.Control.Feedback>Corretto!</Form.Control.Feedback>
        //         </Form.Group>
        //         <Form.Group as={Col} md="12" controlId="cognome">
        //             <Form.Label>Cognome</Form.Label>
        //             <Form.Control
        //                 required
        //                 type="text"
        //                 placeholder="Cognome"
        //                 defaultValue={cookies.cognome}
        //             />
        //             <Form.Control.Feedback>Corretto!</Form.Control.Feedback>
        //         </Form.Group>
        //         <Form.Group as={Col} md="12" controlId="telefono">
        //             <Form.Label>Telefono</Form.Label>
        //             <Form.Control
        //                 required
        //                 type="text"
        //                 placeholder="Telefono"
        //                 onInput={(e) => {e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');}}
        //                 defaultValue={cookies.telefono}
        //             />
        //             <Form.Control.Feedback>Corretto!</Form.Control.Feedback>
        //         </Form.Group>
        //             <Form.Group as={Col} controlId="dataArrivo">
        //                 <FormItem label="Data e Ora di arrivo">
        //                     <DatePicker
        //                         required
        //                         showTime={{ format: 'HH:mm', minuteStep: 15 }}
        //                         value={startDate}
        //                         onChange={setStartDate}
        //                         disabledDate={current => {
        //                             // Disabilita le date precedenti a oggi e i giorni della settimana tranne sabato e domenica
        //                             return current && (current.isBefore(dayjs().endOf('day')) || ![6, 0].includes(current.day()));
        //                         }}
        //                         format="YYYY-MM-DD HH:mm"
        //                     />
        //                 </FormItem>
        //             </Form.Group>
        //
        //     </Row>
        //     <button className={classes["button--alt"]} onClick={onClose}>
        //         Chiudi
        //     </button>
        //     {hasItems && (
        //         <button className={classes.button} type="submit">
        //             Ordina
        //         </button>
        //     )}
        // </Form>
    );
}

export default FormOrder;
