import Modal from "./Modal";
import classes from '../Cart/Cart.module.css'

const ModalConfirm = ({setCartResposeShow}) => {

    return (<Modal onClose={() => setCartResposeShow(false)}>
        <h1>Conferma Ordine</h1>
        <div className={classes.actions}>
            <p style={{color: 'green'}}>Ordine effettuato con successo!</p>
        </div>
        <div className={classes.actions}>
            <button className={classes['button--alt']} onClick={() => setCartResposeShow(false)}>Chiudi</button>
        </div>
    </Modal>);
}
export default ModalConfirm;
