import ReactDOM from 'react-dom/client';
import React from 'react';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter} from "react-router-dom";
import itIT from 'antd/lib/locale/it_IT';
import {ConfigProvider} from "antd";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
        <BrowserRouter>
            <ConfigProvider locale={itIT}>
            <App/>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>
);
