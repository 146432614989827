import classes from "../Meals/AvailableMeals.module.css";
import Card from "../UI/Card";
import MealsSummary from "../Meals/MealsSummary";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import classesButton from "../Cart/Cart.module.css";
import apiService from "../../mongo/apiService";
import Row from "react-bootstrap/Row";
import {Accordion} from "react-bootstrap";
import moment from "moment";

const StatoOrdine = ({setCurrentLoadingState}) => {
    const [validated, setValidated] = useState(false);
    const [orederFiltered, setOrderFiltered] = useState([]);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            console.log("chiamata alla api");

            let requestObj = {
                numeroTelefono: form["telefono"].value,
            };
            setCurrentLoadingState(true);
            apiService
                .searchOrders(requestObj)
                .then((response) => {
                    if (!!response.data) {
                        setOrderFiltered(response.data);
                        setCurrentLoadingState(false);
                    }
                    setCurrentLoadingState(false);
                })
                .catch((error) => {
                });
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
    }, [orederFiltered]);

    let orderItems;
    if (orederFiltered.length === 0) {
        orderItems = <div className={"text-center"}><p>Nessun ordine trovato</p></div>;
    } else {
        orderItems = orederFiltered.map((ordine, index) => {
            return (
                <Accordion key={ordine.id}>
                    <Accordion.Item eventKey={index.toString()} key={ordine.id}>
                        <Accordion.Header>
                            Nome #{ordine.nomeCliente} - {ordine.prezzoTotale}€
                            <span
                                style={{
                                    display: "inline-block",
                                    marginLeft: "10px",
                                    borderRadius: "50%",
                                    width: "12px",
                                    height: "12px",
                                    backgroundColor: ordine.preparato ? "green" : "red",
                                }}
                            ></span>
                            <span style={{marginLeft: "5px"}}>
      {ordine.preparato ? "Preparato" : "Da preparare"}
    </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>Prezzo totale: {ordine.prezzoTotale} €</p>
                                <p>Data di arrivo: {moment(ordine.dataArrivo).format('YYYY-MM-DD HH:mm:ss')}</p>
                            </div>
                            <ul>
                                {ordine.prodottoList.map((prodotto) => (
                                    <li key={prodotto.name}>
                                        {prodotto.amount}{" "}
                                        {!prodotto.saleType || prodotto.saleType === "peso"
                                            ? prodotto.amount < 1
                                                ? "g"
                                                : "kg"
                                            : prodotto.saleType === "pezzo"
                                                ? prodotto.amount > 1
                                                    ? "pezzi"
                                                    : "pz"
                                                : prodotto.saleType === "mazzo"
                                                    ? prodotto.amount > 1
                                                        ? "mazzi"
                                                        : "mazzo"
                                                    : ""
                                        }{" di "}
                                        {prodotto.name} - {prodotto.price}€
                                    </li>
                                ))}
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            );
        });
    }

    return (
        <>
            <MealsSummary
                title={"Verifica lo stato del tuo ordine"}
                firstDescription={
                    "Di seguito potrai verificare lo stato del tuo ordine"
                }
                secondDescription={
                    "inserisci il numero di telefono con cui hai effettuato l' ordine per vederne lo stato"
                }
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "Center",
                    alignItems: "Center",
                }}
            >

                <section className={classes.meals}>
                    <Card>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md="9" controlId="telefono">
                                    <Form.Label>
                                        Inserire il numero di telefono con cui hai effettuato l' ordine
                                    </Form.Label>
                                    <Form.Control required type="text" placeholder="Telefono"/>
                                    <Form.Control.Feedback>Corretto!</Form.Control.Feedback>
                                </Form.Group>
                                <Col md="3" className="d-flex align-items-end justify-content-end">
                                    <div className={classesButton.actions}>
                                        <button className={classesButton.button} type="submit">
                                            Cerca Ordine
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </section>
            </div>

            <section className={classes.mealslarge}>
                <Card>{orderItems}</Card>
            </section>
        </>
    );
};

export default StatoOrdine;
