import './navbar.css';
import {Nav, Navbar, NavLink} from "react-bootstrap";
import {Link} from "react-router-dom";
import classes from "./navbar.css"
const Navigationbar = () => {


    return (
        <Navbar collapseOnSelect bg="dark" variant="dark"  >
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll"/>
            <Navbar.Collapse>
                <Nav>
                    <NavLink eventKey="1" as={Link} to="/">Prodotti disponibili</NavLink>
                    <NavLink eventKey="2" as={Link} to="/ricettario">Ricettario</NavLink>
                    <NavLink eventKey="3" as={Link} to="/statoOrdine">Stato Ordine</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Navigationbar