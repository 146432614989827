import {Fragment} from 'react';

import MealsSummary from './MealsSummary';
import AvailableMeals from './AvailableMeals';

const Meals = ({setCurrentLoadingState}) => {
    return (
        <Fragment>
            <MealsSummary
                title={"Ordina i Tuoi Prodotti Biologici Online e Risparmia Tempo!"}
                firstDescription={"Scopri la nostra selezione di prodotti biologici freschi e deliziosi, coltivati con cura e rispetto per l'ambiente, disponibili per l'ordine online."}
                secondDescription={"Ordina ora e ritira direttamente al banco barbu florentina a san paolo, senza perdere tempo in fila."}
            />

            <AvailableMeals setCurrentLoadingState={setCurrentLoadingState}/>
        </Fragment>
    );
};

export default Meals;
